import React, {useState} from "react";
import Layout from "../components/layout-contents";
import Container from "../components/container";
import { graphql } from "gatsby";
import styles from "../styles/events.module.css";


const Post = (props) => (
  <div style={{ textAlign: "center"}}>
    <img src={props.image} alt="" />
  </div>
);

const ShowMoreButton = ({ onClick }) => (
    <div onClick={onClick} className={styles.showMoreContainer}>
        <a className={styles.showMore}>SHOW MORE</a>
    </div>
)
export default ({ data }) => {
    const [limit, setLimit] = useState(5);
    const [events, setEvents] = useState((data.allEventsJson.edges || []).slice(0, limit));
    const showMore = () => {
        setEvents(data.allEventsJson.edges.slice(0, limit + 5));
        setLimit(limit + 5);
    }

    return (
        <Layout>
            <Container>
                <h1 className='page_title'>
                    <img
                        className='page_title_image'
                        src="/images/home/logo_party.png"
                        alt="party"
                    />
                </h1>
                {events.map(({node}) => (
                    <Post image={node.image}/>
                ))}
                {limit < data.allEventsJson.edges.length && (
                    <ShowMoreButton onClick={showMore}>Show more</ShowMoreButton>
                )}
            </Container>
        </Layout>
    )
};

export const query = graphql`
  query {
    allEventsJson {
      edges {
        node {
          image
        }
      }
    }
  }
`;
